import {AbstractComponent, ComponentLoader} from "ui-base/src/AbstractComponent";

declare const $: any;

export class Tabs extends AbstractComponent {
    public static selector: string = 'tabs-component';
    public tabNameAttribute: string = 'tabs-tab-name';
    public tabPageAttribute: string = 'tabs-page-name';
    public tabActiveClass: string = 'tabs__tab--active';
    public tabPageActiveClass: string = 'tabs__page--active';
    private tabNames: Array<string> = [];

    public init() {

        // find all tabNames hooked to this component
        this.tabNames = $('[tabs-tab-name]', this.componentElement).map(function () {

            // return the attribute name. this refers to current object in map
            return $(this).attr('tabs-tab-name');

        });

        this.setupClickEvents();
        this.setupHashChangeEvents();
    }

    private setupHashChangeEvents()
    {
        this.getDI().getUrlModule().onHashChange((hash) => {
            if ($.inArray(hash, this.tabNames) !== -1)
            {
                this.switchTab(hash);
            }
        })
    }

    private setupClickEvents() {

        $("[" + this.tabNameAttribute + "]", this.getComponentElement()).on("click", (e) => {

            // Get the current tab name
            let tabName = $(e.target).attr(this.tabNameAttribute);

            // run the switch tab method, passing in the tab name
            this.switchTab(tabName);

        });

    }

    private switchTab(tabName) {

        // Remove the active class from all tabs
        $("[" + this.tabNameAttribute + "]", this.getComponentElement()).removeClass(this.tabActiveClass);

        // Add the active class to the clicked tab
        $('[' + this.tabNameAttribute + '="' + tabName + '"]', this.getComponentElement()).addClass(this.tabActiveClass);

        // Removed the active class for all tab pages
        $("[" + this.tabPageAttribute + "]", this.getComponentElement()).removeClass(this.tabPageActiveClass);

        // Add the active tab page class to the page that matched the clicked tab
        $('[' + this.tabPageAttribute + '="' + tabName + '"]', this.getComponentElement()).addClass(this.tabPageActiveClass);

    }

}

new ComponentLoader(Tabs);
